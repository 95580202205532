<template>
  <div class="background">
    <div class="typeArea content">
      <div class="content-top">
        <div class="top-title">
          {{scientificResearchProjectDetails.PROJECT_NAME}}
        </div>
        <div
          class="top-desvribe"
          style="color:#999999"
          v-if="scientificResearchProjectDetails.PROJECT_NAME_TRANSLATE!==null&&scientificResearchProjectDetails.PROJECT_NAME_TRANSLATE!==''"
        >
          参考译文：{{scientificResearchProjectDetails.PROJECT_NAME_TRANSLATE}}
        </div>
        <div class="top-tag">
          <div class="tag-left">
            <div
              class="tag-price"
              v-if="scientificResearchProjectDetails.PROJECT_FUNDS!==null"
            >
              {{scientificResearchProjectDetails.PROJECT_FUNDS}}{{scientificResearchProjectDetails.PROJECT_FUNDS_UNIT}}
            </div>
            <div class="tag">
              <div
                class="tag-item"
                v-for="(item2,index) in scientificResearchProjectDetails.tagsArray"
                :key="index"
                @mouseenter="tagmouseenter($event)"
                @mouseleave="tagmouseleave($event)"
              >
                <div class="text">
                  {{item2}}
                </div>
                <div
                  class="add"
                  @click="tagtoSubscribe($event)"
                >
                  +
                </div>
              </div>
            </div>

          </div>
          <div class="tag-right">
            <div class="share-item">
              <div class="share-image">
                <img
                  src="../../../../assets/image/科研项目_slices/详情页/微信1.png"
                  alt=""
                >
              </div>
              <div class="share-text">告诉小伙伴</div>
              <div class="wxer-code">
                <vue-qr
                  ref="Qrcode"
                  :logoSrc='imgUrl'
                  :text="codeUrl"
                  :size="200"
                  :margin="8"
                  qid="testQrId"
                  :logoScale='10'
                  :callback="callback"
                ></vue-qr>
                <!-- <a
                  :href="exportLink"
                  @click="downloadImg"
                  :download="downloadFilename"
                >下载二维码</a> -->

              </div>
              <!-- <div
                class="wxer-code"
                id="wxer-code"
                ref="paycode"
                :style="{transform: 'scale('+$store.state.suofangbi+')'}"
              > -->
              <!-- <img
                  src="../../../../assets/image/科研项目_slices/详情页/科研人个人二维码@2x.png"
                  alt=""
                > -->
              <!-- </div> -->
            </div>
            <!-- <div
              class="share-item"
              @click="attentionProject"
            >
              <div class="share-image">
                <img
                  v-if="isAttention"
                  src="../../../../assets/image/科研项目_slices/详情页/五角星选中.png"
                  alt=""
                >
                <img
                  v-else
                  src="../../../../assets/image/科研项目_slices/详情页/五角星.png"
                  alt=""
                >
              </div>
              <div class="share-text">收藏项目</div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="content-middle">
        <div class="middle-title">
          基本情况
        </div>
        <div class="basicSituation">
          <div class="basicSituation-item">
            <div class="item-title">
              项目编号
            </div>
            <div class="item-details">
              {{scientificResearchProjectDetails.PROJECT_NUMBER}}
              <span
                class="wrongline"
                v-if="scientificResearchProjectDetails.PROJECT_NUMBER===null"
              >——</span>
            </div>
          </div>
          <div class="basicSituation-item">
            <div class="item-title">
              项目金额
            </div>
            <div class="item-details details-color">
              {{scientificResearchProjectDetails.PROJECT_FUNDS}} <span v-if="scientificResearchProjectDetails.PROJECT_FUNDS!==null">({{scientificResearchProjectDetails.PROJECT_FUNDS_UNIT}})</span>
              <span
                class="wrongline"
                v-if="scientificResearchProjectDetails.PROJECT_FUNDS===null"
              >——</span>
            </div>
          </div>
          <div class="basicSituation-item">
            <div class="item-title">
              承担机构
            </div>
            <div class="item-details details-color">
              {{scientificResearchProjectDetails.PROJECT_ORGANIZATION}}
            </div>
            <!-- <div
              class="to-subscribe-to"
              :class="{alerdSubscribe:isSubscribe}"
              @click="politicsInTheTenderDetailsSubscribe"
              v-if="scientificResearchProjectDetails.PROJECT_ORGANIZATION_ID!==null&&scientificResearchProjectDetails.PROJECT_ORGANIZATION_ID!==''&&scientificResearchProjectDetails.PROJECT_ORGANIZATION_ID!==false"
            >
              <div class="text">
                订阅
              </div>
              <div class="add">+</div>
            </div> -->
          </div>
          <div class="basicSituation-item">
            <div class="item-title">
              资助机构
            </div>
            <div class="item-details details-color">
              {{scientificResearchProjectDetails.PROJECT_ORGANIZATION_FUND}}
              <span
                class="wrongline"
                v-if="scientificResearchProjectDetails.PROJECT_ORGANIZATION_FUND===null"
              >——</span>
            </div>
            <!-- <div
              class="to-subscribe-to"
              :class="{alerdSubscribe:isSubscribe1}"
              @click="politicsInTheTenderDetailsSubscribe1"
              v-if="scientificResearchProjectDetails.PROJECT_ORGANIZATION_FUND!==null&&scientificResearchProjectDetails.PROJECT_ORGANIZATION_FUND_ID!==null"
            >
              <div class="text">
                订阅
              </div>
              <div class="add">+</div>
            </div> -->
          </div>
          <div class="basicSituation-item">
            <div class="item-title">
              负 责 人
            </div>
            <div class="item-details details-color">
              <span
                class="wrongline"
                v-if="scientificResearchProjectDetails.PROJECT_PRINCIPAL==null"
              >——</span>
              <span v-else>{{scientificResearchProjectDetails.PROJECT_PRINCIPAL}}</span>
            </div>
          </div>
          <div class="basicSituation-item">
            <div class="item-title">
              参 与 人
            </div>
            <div class="item-details details-color">
              <span
                class="wrongline"
                v-if="scientificResearchProjectDetails.PROJECT_PARTICIPANT==null"
              >——</span>
              <span v-else>
                {{scientificResearchProjectDetails.PROJECT_PARTICIPANT}}
              </span>
            </div>
          </div>
          <div class="basicSituation-item">
            <div class="item-title">
              起始时间
            </div>
            <div class="item-details">
              {{scientificResearchProjectDetails.dateformatestart}}
            </div>
          </div>
          <div class="basicSituation-item">
            <div class="item-title">
              终止时间
            </div>
            <div class="item-details details-color">
              {{scientificResearchProjectDetails.dateformateend}}
            </div>
          </div>
          <div class="basicSituation-item">
            <div class="item-title">
              关 键 词
            </div>
            <div class="item-details">
              {{scientificResearchProjectDetails.PROJECT_KEYWORDS}}
            </div>
          </div>
        </div>
        <div class="project-paper">
          <div class="paper-title">项目摘要</div>
          <div class="paper-content">
            <div
              class="content-e" style="white-space:pre-wrap"
              v-if="scientificResearchProjectDetails.PROJECT_ABSTRACT!==null||scientificResearchProjectDetails.PROJECT_ABSTRACT!==false"
              v-html="scientificResearchProjectDetails.PROJECT_ABSTRACT"
            >
              My project investigates the emergence of collecting and manipulating texts as the dominant method of creating political expertise in Britain from the sixteenth to the eighteenth century. I argue that the practices public and private figures used to amass and mobilize their collections created an environment flooded by tides of paper, and that efforts to navigate this context spurred significant transformations in statecraft, the relationship between state and subjects, and the ways that individuals viewed their distance from political configurations in the past. The archive thus emerges as a privileged space of knowledge formation in early modernity, one whose rise heralded epochal transformations in both political and intellectual spheres.
            </div>
            <div
              class="content-c" style="white-space:pre-wrap"
              v-if="scientificResearchProjectDetails.PROJECT_ABSTRACT_TRANSLATE!==null||scientificResearchProjectDetails.PROJECT_ABSTRACT_TRANSLATE!==false"
              v-html="scientificResearchProjectDetails.PROJECT_ABSTRACT_TRANSLATE"
            >
              我的项目调查了从16世纪到18世纪在英国,收集和处理文本作为创造政治专业知识的主要方法的出现。我认为,公共人物和私人人物用来收集和动员他们的藏品的做法创造了一个被纸潮淹没的环境,而在这种背景下努力导航,促使国家工艺、国家与主体之间的关系发生了重大转变,以及人们看待自己与过去政治格局的距离的方式。因此,档案馆在早期现代性中成为一个特殊的知识形成空间,其兴起预示着政治和知识领域的划时代变革。
            </div>
            <div v-if="(scientificResearchProjectDetails.PROJECT_ABSTRACT_TRANSLATE==null||scientificResearchProjectDetails.PROJECT_ABSTRACT_TRANSLATE==false)&&(scientificResearchProjectDetails.PROJECT_ABSTRACT==null||scientificResearchProjectDetails.PROJECT_ABSTRACT==false
              )">
              暂无内容
            </div>

          </div>
          <div class="paper-link">
            <div
              class="original-link"
              v-if="scientificResearchProjectDetails.PROJECT_SOURCE_URL"
              @click="showallcontent"
            >
              原文链接
            </div>
            <!-- <div
              class="noriginal-link"
              @click="showallcontent"
              v-else
            >原文链接</div> -->
          </div>
        </div>
      </div>
      <div class="list-table">
        <div class="bottom-header">相似项目</div>
        <table>
          <thead>
            <tr>
              <td>项目名称</td>
              <td>所在区域</td> 
              <td>承担机构</td>
              <td>资助机构</td>
              <td>项目金额</td>
              <td>项目时间</td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in similarProjects.slice(0,10)"
              :key="item.id"
            >
              <td @click="toGlobalResearchDetail(item)">
                <div class="tdtitle"> {{item.PROJECT_NAME}}</div>
                <!-- <div
                  class="tdcontent"
                  style="color:#999999"
                  v-if="item.PROJECT_NAME_TRANSLATE!==null&&item.PROJECT_NAME_TRANSLATE!==''"
                >
                  参考译文：{{item.PROJECT_NAME_TRANSLATE}}
                </div> -->
              </td>
               <td>
                <img
                  :src="require(`../../../../assets/image/国旗数据库/${item.PROJECT_ORGANIZATION_FUND_COUNTRY_ID}.svg`)"
                  alt=""
                >
              </td> 
              <td><span
                  class="wrongline"
                  v-if="item.PROJECT_ORGANIZATION==null"
                >——</span> <span v-else>{{item.PROJECT_ORGANIZATION}}</span></td>
              <td><span
                  class="wrongline"
                  v-if="item.PROJECT_ORGANIZATION_FUND==null"
                >——</span> <span v-else>{{item.PROJECT_ORGANIZATION_FUND}}</span> </td>
              <td><span v-if="item.PROJECT_FUNDS==null">——</span> <span v-else>{{item.PROJECT_FUNDS}}</span> <span v-if="item.PROJECT_FUNDS!==null">万美元</span></td>
              <td>
                <!-- <span v-if="item.dateformatestart==='——'">——</span> -->
                <span
                  class="wrongline"
                  v-if="item.GENERATE_TIME==null||item.GENERATE_TIME==''"
                >——</span>
                <span v-else>{{item.GENERATE_TIME.split(' ')[0]}}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { getScientificResearchProjectList, organizationAdd, organizationJudge, subscribeadd, toAttentionProject, cancelAttentionProject, getsimilarprojects } from '../../../../api/details/scientificResearchProjectDetails'
// eslint-disable-next-line no-unused-vars
import { formatDate, formatDate1, makePy, checkCh, mkRslt,formatDate2 } from '../../../../util/tools'
import { Addhistoricalrecordky } from '../../../../api/myRecentInformation'
// eslint-disable-next-line no-unused-vars
import QRCode from 'qrcodejs2'
import vueQr from 'vue-qr'
// eslint-disable-next-line no-unused-vars
import { organizationDelete, organizationllist } from '../../../../api/mySubscription'
export default {
  data () {
    return {
      codeUrl: '', // 二维码地址或内容
      imgUrl: '', // 二维码中间的logo图
      exportLink: '',
      downloadFilename: '', // 下载的图片文件名
      scientificResearchProjectDetails: '',
      // 相似项目列表
      similarProjects: [],
      // 是否关注
      isAttention: false,
      // 承担机构是否订阅
      isSubscribe: false,
      // 资助机构是否订阅
      isSubscribe1: false,
      // 是否隐藏原文
      ishiddencontent: true

    }
  },
  created () {
    // 获取初始化数据
    this.getData();
    this.goTop();
  },
  components: {
    vueQr
  },
  mounted () {
    this.qrcode()
  },
  methods: {
    callback (dataUrl, id) {
      var scene = this.$store.state.userid
      var wid = (this.$data.scientificResearchProjectDetails.PROJECT_ORGANIZATION_ID + '').split(';')[0]
      this.imgUrl = dataUrl + '&scene=' + scene + '&wid=' + wid
    },
    // 格式化日期格式
    formateDateList (arr) {
      arr.forEach(element => {
        if (element.PROJECT_DATE) {
          element.dateformatestart = formatDate(parseInt(element.PROJECT_DATE)).trim().replace(/-/g, '/')
          element.dateformateend = formatDate(parseInt(element.PROJECT_DATE_END)).trim().replace(/-/g, '/')
        }
        if (element.TENDER_START_TIME) {
          element.dateformatestart = formatDate(parseInt(element.TENDER_START_TIME)).trim().replace(/-/g, '/')
          element.dateformateend = formatDate(parseInt(element.TENDER_END_TIME)).trim().replace(/-/g, '/')
        }
        if (element.PROJECT_START || element.PROJECT_START === false) {
          if (element.PROJECT_START === false) {
            element.dateformatestart = '——'
          } else {
            element.dateformatestart = formatDate(parseInt(element.PROJECT_START)).trim()
          }
          if (element.PROJECT_END === false) {
            element.dateformateend = '——'
          } else {
            element.dateformateend = formatDate(parseInt(element.PROJECT_END)).trim()
          }
        }
      })
    },
    // 跳转到全球科研项目详情页
    toGlobalResearchDetail (item) {
      localStorage.setItem('navActive', 4)
      this.$store.commit('setnavActive', 4)
      const id = item.PROJECT_ID
      const uid = this.$store.state.userid
      const params = {
        title_id: id,
        user_id: uid
        // type: 1
      }
      Addhistoricalrecordky(params).then((res) => {
        console.log('添加历史记录成功', res)
      }, (err) => {
        console.log('添加历史记录异常', err)
      })
      let md = item.PROJECT_MD5
      if (md === null) {
        md = ''
      }
      this.$router.push({
        name: 'scientificResearchProjectDetails',
        params: {
          id: id
        },
        query: {
          PROJECT_MD5: md
        }
      })
      this.getData1(id)
      window._czc.push(['_trackEvent', '点击', '跳转到全球科研项目详情页'])
    },
    // 项目摘要数据分段
    datapiecewise (str) {
      const newstr = str.replace(/([一,二,三,四,五,六,七,八,九,十]+)、/g, '<br><br>$1、')
        .replace(/([0-9]+)\./g, '<br><br>$1.')
        .replace(/邮政编码：/g, '<br><br>邮政编码：')
        .replace(/联 系 人：/g, '<br><br>联 系 人：')
        .replace(/联系电话：/g, '<br><br>联系电话：')
        .replace(/电子邮箱：/g, '<br><br>电子邮箱：')
        .replace(/附件：/g, '<br><br>附件：')
        .replace(/([\u4e00-\u9fa5]+)(\s+)([\u4e00-\u9fa5]+)(\s+)(\d{4}年\d{1,2}月\d{1,2}日)(\s+)(附件\d*)(\s+)([\u4e00-\u9fa5]+)/g, '$1$2<br><br>$3$4<br>$5$6<br><br>$7$8<br>$9')
        // .replace(/<br><br>1./g, '1.')
        .replace(/([一,二,三,四,五,六,七,八,九,十]+、)([\u4e00-\u9fa5]+)(\s+)([\u4e00-\u9fa5]+)/g, '$1$2<br><br>$3$4')
      console.log(str)
      return newstr
    },
    // 数据获取
    getData () {
      const that = this
      const id = this.$route.params.id
      // 获取政采招标列表
      getScientificResearchProjectList({ limit: 12, page: 1, id: id }).then(res => {
        console.log('科研项目详情', res)
        // 该方法会给res.data中添加dateformate字段用来存储格式化后的创建时间
        that.formateDateList(res.data)
        res.data = that.processingReturnData(res.data)
        that.$data.scientificResearchProjectDetails = res.data[0]
        document.getElementsByName('keywords')[0].content = this.$data.scientificResearchProjectDetails.PROJECT_KEYWORDS
        document.getElementsByTagName('title')[0].innerHTML = this.$data.scientificResearchProjectDetails.PROJECT_NAME
        console.log('处理后科研详情', res.data[0])
        getsimilarprojects({
          id: res.data[0].PROJECT_ID
        }).then(res => {
          console.log('科研相似项目返回', res)
          that.formateDateList(res.data)
          res.data = that.processingReturnData(res.data)
          that.$data.similarProjects = res.data
        })

        this.getorganizationJudge()
      }, err => console.log(err))
    },
    getData1 (dsf) {
      const that = this
      const id = dsf
      // 获取政采招标列表
      getScientificResearchProjectList({ limit: 12, page: 1, id: id }).then(res => {
        console.log('科研项目详情', res)
        // 该方法会给res.data中添加dateformate字段用来存储格式化后的创建时间
        that.formateDateList(res.data)
        res.data = that.processingReturnData(res.data)
        that.$data.scientificResearchProjectDetails = res.data[0]
        document.getElementsByName('keywords')[0].content = this.$data.scientificResearchProjectDetails.PROJECT_KEYWORDS
        document.getElementsByTagName('title')[0].innerHTML = this.$data.scientificResearchProjectDetails.PROJECT_NAME
        console.log('处理后科研详情', res.data[0])
        getsimilarprojects({
          id: res.data[0].PROJECT_ID
        }).then(res => {
          console.log('科研相似项目返回', res)
          that.formateDateList(res.data)
          res.data = that.processingReturnData(res.data)
          that.$data.similarProjects = res.data
        })

        this.getorganizationJudge()
      }, err => console.log(err))
    },
    // 获取当前机构是否订阅
    getorganizationJudge () {
      const that = this
      const id = that.$store.state.userid
      const oid = (that.$data.scientificResearchProjectDetails.PROJECT_ORGANIZATION_ID + '').split(';')[0]
      const params = {
        user_id: id,
        organization_id: oid
      }
      const params1 = {
        user_id: id,
        organization_id: (that.$data.scientificResearchProjectDetails.PROJECT_ORGANIZATION_FUND_ID + '').split(';')[0]
      }
      organizationJudge(params).then(res => {
        console.log('成功', res)
        if (res.msg === '该机构未订阅!') {
          that.$data.isSubscribe = false
        } else {
          that.$data.isSubscribe = true
        }
      }, err => {
        console.log('失败', err)
      })
      organizationJudge(params1).then(res => {
        console.log('成功', res)
        if (res.msg === '该机构未订阅!') {
          that.$data.isSubscribe1 = false
        } else {
          that.$data.isSubscribe1 = true
        }
      }, err => {
        console.log('失败', err)
      })
    },
    // 时间转换成显示时间
    getTimeToShow (pushTime) {
      const nowtime = Date.parse(new Date())
      const time = nowtime - pushTime * 1000
      if (time < 60000) {
        return '刚刚'
      } else if (time > 60000 && time < 3600000) {
        return Math.floor(time / 60000) + '分钟前'
      } else if (time > 3600000 && time < 86400000) {
        return Math.floor(time / 3600000) + '小时前'
      } else if (time > 86400000 && time < 604800000) {
        return Math.floor(time / 86400000) + '天前'
      } else {
        return formatDate2(pushTime)
      }
    },
    // 根据时间显示周几
    getWeek (pushTime) {
      const date = new Date(parseInt(pushTime * 1000))
      var week = date.getDay()
      console.log('week', week)
      if (week === 0) {
        return '星期日'
      } else if (week === 1) {
        return '星期一'
      } else if (week === 2) {
        return '星期二'
      } else if (week === 3) {
        return '星期三'
      } else if (week === 4) {
        return '星期四'
      } else if (week === 5) {
        return '星期五'
      } else if (week === 6) {
        return '星期六'
      }
    },
    // 计算剩余几天
    getremaintime (startTime, endTime) {
      if (endTime === false) {
        const time = parseInt(Date.parse(new Date())) - parseInt(Date.parse(new Date()))
        const a = `${Math.floor(parseInt(time) / 86400000 + 1)}`
        console.log(a)
        return `${Math.floor(parseInt(time) / 86400000 + 1)}`
      } else {
        const timenow = Date.parse(new Date())
        const timesend = Number(endTime) * 1000
        const time = timesend - timenow
        const a = `${Math.floor(parseInt(time) / 86400000)}`
        console.log(a)
        return `${Math.floor(parseInt(time) / 86400000)}`
      }
    },
    // 返回数据处理
    processingReturnData (arr) {
      const that = this
      arr.forEach(elem => {
        if (elem.PROJECT_KEYWORDS) {
          elem.tagsArray = elem.PROJECT_KEYWORDS.split(';')
        }
        if (elem.TENDER_START_TIME) {
          elem.showTimeText = that.getTimeToShow(elem.TENDER_START_TIME)
          elem.week = that.getWeek(elem.TENDER_START_TIME)
          elem.remainTime = that.getremaintime(elem.TENDER_START_TIME, elem.TENDER_END_TIME)
        }
        if (elem.PROJECT_ABSTRACT) {
          elem.PROJECT_ABSTRACT = that.datapiecewise(elem.PROJECT_ABSTRACT)
        }
      })
      return arr
    },
    // 鼠标移入显示标签订阅按钮
    tagmouseenter (event) {
      console.log(event.currentTarget.firstElementChild.nextElementSibling.classList)
      event.currentTarget.firstElementChild.nextElementSibling.classList.add('show')
    },
    // 鼠标移除隐藏订阅按钮
    tagmouseleave (event) {
      console.log(event.currentTarget.firstElementChild.nextElementSibling.classList)
      event.currentTarget.firstElementChild.nextElementSibling.classList.remove('show')
    },
    // 点击加号订阅标签
    tagtoSubscribe (event) {
      const that = this
      const id = that.$store.state.userid
      const keywords = event.currentTarget.previousElementSibling.innerText
      console.dir(keywords)
      const type = 3
      const params = {
        user_id: id,
        keywords: keywords,
        type: type
      }
      subscribeadd(params).then(res => {
        if (res.code === 1) {
          that.$message({
            message: res.msg,
            type: 'success'
          })
        } else {
          console.log(res)
          that.$message({
            message: res.msg,
            type: 'warning'
          })
        }
      })
      window._czc.push(['_trackEvent', '点击', '点击订阅标签'])
    },
    // 查看原文点击事件
    showallcontent (event) {
      let url = ''
      // eslint-disable-next-line eqeqeq
      if (this.$data.scientificResearchProjectDetails.PROJECT_SOURCE_URL.indexOf('http') == -1) {
        url = `http://${this.$data.scientificResearchProjectDetails.PROJECT_SOURCE_URL}`
      } else {
        url = this.$data.scientificResearchProjectDetails.PROJECT_SOURCE_URL
      }
      window.open(url)
    },
    // 关注项目
    attentionProject () {
      const id = this.$data.scientificResearchProjectDetails.PROJECT_ID
      const type = 3
      const userid = this.$store.state.userid
      const params = {
        title_id: id,
        type: type,
        user_id: userid
      }
      if (this.$data.isAttention === false) {
        this.$data.isAttention = !this.$data.isAttention
        toAttentionProject(params).then(res => {
          console.log('成功', res)
        }, err => {
          console.log('失败', err)
        })
      } else {
        this.$data.isAttention = !this.$data.isAttention
        cancelAttentionProject(params).then(res => {
          console.log('成功', res)
        }, err => {
          console.log('失败', err)
        })
      }
      window._czc.push(['_trackEvent', '点击', '关注科研项目'])
    },
    // 订阅
    politicsInTheTenderDetailsSubscribe () {
      const that = this
      if (that.$data.scientificResearchProjectDetails.PROJECT_ORGANIZATION_ID !== '') {
        const id = that.$store.state.userid
        // const oid = that.$data.scientificResearchProjectDetails.PROJECT_ORGANIZATION.split(' ')[0]
        const oid = (that.$data.scientificResearchProjectDetails.PROJECT_ORGANIZATION_ID + '').split(';')[0]
        if (this.$data.isSubscribe === false) {
          this.$data.isSubscribe = true
          const params = {
            user_id: id,
            organization_id: oid
          }
          organizationAdd(params).then(res => {
            console.log('成功', res)
          }, err => {
            console.log('失败', err)
          })
        } else {
          const id = oid.trim()

          const userid = that.$store.state.userid
          organizationllist({ user_id: userid }).then(res => {
            console.log('res', res)
            const dingyuelist = res
            dingyuelist.forEach(ele => {
              // eslint-disable-next-line eqeqeq
              if (ele.organization_id == id) {
                organizationDelete({ id: ele.id }).then(res => {
                  // eslint-disable-next-line eqeqeq
                  if (res == 1) {
                    that.$data.isSubscribe = false
                  } else {

                  }
                })
              }
            })
          })
        }
        window._czc.push(['_trackEvent', '点击', '订阅科研项目承担机构'])
      }
    },
    // 订阅
    politicsInTheTenderDetailsSubscribe1 () {
      const that = this
      if (that.$data.scientificResearchProjectDetails.PROJECT_ORGANIZATION_FUND_ID !== '') {
        const id = that.$store.state.userid
        // const oid = that.$data.scientificResearchProjectDetails.PROJECT_ORGANIZATION.split(' ')[0]
        const oid = (that.$data.scientificResearchProjectDetails.PROJECT_ORGANIZATION_FUND_ID + '').split(';')[0]
        if (this.$data.isSubscribe1 === false) {
          this.$data.isSubscribe1 = true
          const params = {
            user_id: id,
            organization_id: oid
          }
          organizationAdd(params).then(res => {
            console.log('成功', res)
          }, err => {
            console.log('失败', err)
          })
        } else {
          const id = oid.trim()

          const userid = that.$store.state.userid
          organizationllist({ user_id: userid }).then(res => {
            console.log('res', res)
            const dingyuelist = res
            dingyuelist.forEach(ele => {
              // eslint-disable-next-line eqeqeq
              if (ele.organization_id == id) {
                organizationDelete({ id: ele.id }).then(res => {
                  // eslint-disable-next-line eqeqeq
                  if (res == 1) {
                    that.$data.isSubscribe1 = false
                  } else {

                  }
                })
              }
            })
          })
        }
        window._czc.push(['_trackEvent', '点击', '订阅科研项目资助机构'])
      }
    },
    goTop () {
      cancelAnimationFrame(this.timer)
      const self = this
      self.timer = requestAnimationFrame(function fn () {
        const oTop = document.body.scrollTop || document.documentElement.scrollTop
        if (oTop > 0) {
          document.body.scrollTop = document.documentElement.scrollTop = oTop - 50
          self.timer = requestAnimationFrame(fn)
        } else {
          cancelAnimationFrame(self.timer)
        }
      })
    },
    qrcode () {
      var scene = this.$store.state.userid
      const address = this.$store.state.wxprojectbaseurl + '&scene=' + scene + '&wid=' + this.$route.params.id
      this.$data.codeUrl = address
    }
  }

}

</script>

<style lang="scss" scoped>
.wrongline {
  color: #000 !important;
  font-size: 12px !important;
}
// 版心
.background {
  background-color: #f1f2f6;
  overflow: hidden;
}
.show {
  display: block !important;
}
.typeArea {
  width: 1200px;
  margin: 0 auto;
}
.content {
  margin-top: 27px;
  .content-top {
    background-color: #fff;
    box-sizing: border-box;
    padding: 40px;
    padding-bottom: 29px;
    margin-bottom: 20px;
    .top-title {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #282828;
      margin-bottom: 10px;
    }
    .top-desvribe {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
      margin-bottom: 20px;
    }
    .top-tag {
      display: flex;
      justify-content: space-between;
      align-items: start;
      .tag-left {
        display: flex;
        align-items: start;
        .tag-price {
          height: 28px;
          border: 1px solid #8c6314;
          padding: 0 13px;
          line-height: 28px;
          font-size: 15px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #8c6314;
          margin-right: 32px;
        }
        .tag {
          display: flex;
          flex-wrap: wrap;
          max-width: 520px;
          .tag-item {
            cursor: pointer;
            height: 28px;
            padding: 0 12px;
            margin-right: 10px;
            background-color: #fcfafd;
            font-size: 15px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 28px;
            color: #5e068c;
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            .text {
              margin-right: 10px;
            }
            .add {
              display: none;
            }
          }
        }
      }
      .tag-right {
        display: flex;
        justify-content: right;
        align-items: center;
        .share-item {
          height: 28px;
          cursor: pointer;
          position: relative;
          margin-left: 29px;
          display: flex;
          align-items: center;
          .share-image {
            margin-right: 6px;
            img {
              width: 18.04px;
              height: 14.57px;
            }
          }
          .share-text {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #282828;
          }
          .wxer-code {
            transform-origin: 0 0;
            display: none;
            position: absolute;
            border: 5px solid #000;
            top: 28px;
            left: 24px;
            &::after {
              content: "";
              width: 0;
              height: 0;
              border-left: 7px solid transparent;
              border-right: 7px solid transparent;
              border-bottom: 7px solid #000;
              position: absolute;
              top: -10px;
              left: 29px;
            }
            img {
              width: 72px;
              height: 72px;
            }
          }
        }
        .share-item:hover {
          .wxer-code {
            display: block;
          }
        }
      }
    }
  }
  .content-middle {
    padding: 40px;
    background-color: #fff;
    margin-bottom: 20px;
    .middle-title {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      margin-bottom: 31px;
    }
    .basicSituation {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 48px;
      &::after {
        content: "";
        width: 50%;
        height: 58px;
        border-top: 1px solid #f0f0f0;
        border-bottom: 1px solid #f0f0f0;
        height: 70px;
        box-sizing: border-box;
      }
      .basicSituation-item {
        width: 50%;
        display: flex;
        align-items: center;
        border-top: 1px solid #f0f0f0;
        border-bottom: 1px solid #f0f0f0;
        padding: 20px 0px;
        height: 70px;
        box-sizing: border-box;
        .item-title {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #666666;
          margin-right: 38px;
          width: 66px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .item-details {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          margin-right: 23px;
        }
        .details-color {
          max-width: 360px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #5e068c;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .to-subscribe-to {
          cursor: pointer;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          height: 21.36px;
          border: 1px solid #990263;
          border-radius: 3px;
          padding: 0px 6px;
          display: flex;
          .text {
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 21px;
            color: #990263;
            margin-right: 4px;
          }
          .add {
            color: #990263;
          }
        }
        .alerdSubscribe {
          border: 0.01rem solid #707070 !important;
          .text {
            color: #707070;
          }
          .add {
            color: #707070;
          }
        }
      }
    }
    .project-paper {
      .paper-title {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #333333;
        margin-bottom: 30px;
      }
      .paper-content {
        border: 1px solid #f0f0f0;
        padding: 23px 16px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 30px;
        color: #333333;
        margin-bottom: 20px;
        max-height: 400px;
        min-height: 10px;
        overflow-y: scroll;
        word-break: break-all;
        .hiddecontent {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          line-clamp: 3;
          -webkit-box-orient: vertical;
          width: 1067px;
        }
      }
      .paper-link {
        display: flex;
        align-items: center;
        .original-link {
          cursor: pointer;
          height: 32px;
          background: #5e068c;
          border-radius: 4px;
          color: #fff;
          padding: 0px 14px;
          box-sizing: border-box;
          font-size: 15px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          display: flex;
          align-items: center;
          margin-right: 14px;
        }
        .noriginal-link {
          cursor: pointer;
          height: 32px;
          background: #b9b9b9;
          border-radius: 4px;
          color: #fff;
          padding: 0px 14px;
          box-sizing: border-box;
          font-size: 15px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          display: flex;
          align-items: center;
          margin-right: 14px;
        }
      }
    }
  }
  .list-table {
    .bottom-header {
      padding: 40px;
      padding-bottom: 30px;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      background-color: #fff;
    }
    margin-top: 11px;
    margin-bottom: 23px;
    table {
      cursor: pointer;
      width: 100%;
      border-spacing: 0px;
      border-collapse: collapse;
      table-layout: fixed;
      thead {
        tr {
          border-bottom: 1px solid #f0f0f0;
          height: 55px;
          td {
            box-sizing: border-box;
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 21px;
            color: #3b3b3b;
            opacity: 1;
            background-color: #f9f9f9;
            border: 0px;
          }
          td:nth-of-type(1) {
            text-align: left;
            width: 511px;
            padding-left: 30px;
          }
          td:nth-of-type(2) {
            width: 196px;
          }
          td:nth-of-type(3) {
            width: 215px;
          }
          td:nth-of-type(4) {
            padding-left: 10px;
            width: 100px;
          }
          td:nth-of-type(5) {
            text-align: right;
            padding-right: 14px;
          }
          td:nth-of-type(6) {
            text-align: right;
            padding-right: 14px;
          }
        }
      }
      tbody {
        background-color: #fff;
        tr {
          border-bottom: 1px solid #f0f0f0;
          height: 90px;
          box-sizing: border-box;
          &:hover {
            background-color: #f9f9f9;
          }
          td {
            box-sizing: border-box;
            font-size: 15px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #282828;
            opacity: 1;
            .tdtitle {
              font-size: 15px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              line-height: 18px;
              color: #282828;
              margin-bottom: 6px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              padding-right: 14px;
            }
            img {
              width: 40.21px;
              height: 27, 69px;
            }
          }
          td:nth-of-type(1) {
            padding-right: 6px !important;
            text-align: left;
            padding: 24px 30px;
            padding-right: 0px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          td:nth-of-type(2) {
            padding-right: 6px !important;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          td:nth-of-type(3) {
            padding-right: 14px !important;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          td:nth-of-type(4) {
            padding-right: 6px !important;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          td:nth-of-type(5) {
            width: 92.5px;
            text-align: right;
            font-size: 15px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            line-height: 18px;
            color: #990263;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 14px;
          }
          td:nth-of-type(6) {
            text-align: right;
            padding-right: 14px;
          }
          .tdupto {
            font-size: 15px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 18px;
            color: #707070 !important;
          }
        }
      }
    }
  }
}
</style>
