import request1 from '../../util/loginrequest'
import request from '../../util/request'
// 获取科研项目列表
export const getScientificResearchProjectList = (params) => {
  return request({
    url: '/api/demo/Project/list',
    method: 'post',
    data: {
      scientific_classification_one: params.scientific_classification_one || '',
      release_time_month: params.release_time_month || '',
      release_time_year: params.release_time_year || '',
      release_time_years: params.release_time_years || '',
      project_organization_fund_country_id: params.project_organization_fund_country_id || '',
      limit: params.limit || '',
      page: params.page || '',
      title: params.title || '',
      id: params.id || ''
    }

  })
}
// 机构订阅添加
export const organizationAdd = (params) => {
  return request({
    url: '/api/demo/subscribe/organization_add',
    method: 'post',
    data: {
      user_id: params.user_id || '',
      organization_id: params.organization_id || ''
    }
  })
}
// 机构是否订阅
// 机构是否订阅
export const organizationJudge = (params) => {
  return request1({
    url: '/api/demo/subscribe/organizationJudge',
    method: 'post',
    data: {
      user_id: params.user_id || '',
      organization_id: params.organization_id || ''
    }
  })
}
// 标签订阅
export const subscribeadd = (params) => {
  return request1({
    url: '/api/demo/subscribe/add',
    method: 'post',
    data: {
      user_id: params.user_id || '',
      keywords: params.keywords || '',
      type: params.type || '',
      loacal: params.loacal || '',
      research_field: params.research_field || '',
      PROJECT_TYPE: params.PROJECT_TYPE || ''
    }
  })
}
// 关注项目
export const toAttentionProject = (params) => {
  return request1({
    url: '/api/demo/subscribe/collectAdd',
    method: 'post',
    data: {
      title_id: params.title_id || '',
      type: params.type || '',
      user_id: params.user_id || ''
    }
  })
}
// 取消关注项目
export const cancelAttentionProject = (params) => {
  return request1({
    url: '/api/demo/subscribe/collectDel',
    method: 'post',
    data: {
      title_id: params.title_id || '',
      type: params.type || '',
      user_id: params.user_id || ''
    }
  })
}
// 获取相似项目
export const getsimilarprojects = (params) => {
  return request1({
    url: '/api/demo/Project/similarity',
    method: 'post',
    data: {
      id: params.id
    }
  })
}
